/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse,
  FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponse,
  FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest,
  FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse,
  FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest,
  FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse,
  FxIdWebFeaturesAuthAuthenticateFromGameRequest,
  FxIdWebFeaturesAuthAuthenticateFromGameResponse,
  FxIdWebFeaturesAuthAuthenticateFromImportRequest,
  FxIdWebFeaturesAuthAuthenticateFromImportResponse,
  FxIdWebFeaturesAuthAuthenticateFromSocialRequest,
  FxIdWebFeaturesAuthAuthenticateFromSocialResponse,
  FxIdWebFeaturesAuthAuthenticateFromTokenRequest,
  FxIdWebFeaturesAuthAuthenticateFromTokenResponse,
  FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest,
  FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiResponse,
  FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest,
  FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse,
  FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest,
  FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse,
  FxIdWebFeaturesAuthCreateTempTokenRequest,
  FxIdWebFeaturesAuthCreateTempTokenResponse,
  FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest,
  FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponse,
  FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest,
  FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse,
  FxIdWebFeaturesAuthOauthUserInfoResponse,
  FxIdWebFeaturesAuthTwitchConnectRequest,
  FxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest,
} from '../models';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseFromJSON,
    FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseToJSON,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseFromJSON,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseToJSON,
    FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequestFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequestToJSON,
    FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponseFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponseToJSON,
    FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestToJSON,
    FxIdWebFeaturesAuthAuthenticateFromFirebaseResponseFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromFirebaseResponseToJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameRequestFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameRequestToJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseToJSON,
    FxIdWebFeaturesAuthAuthenticateFromImportRequestFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromImportRequestToJSON,
    FxIdWebFeaturesAuthAuthenticateFromImportResponseFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromImportResponseToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialResponseFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialResponseToJSON,
    FxIdWebFeaturesAuthAuthenticateFromTokenRequestFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromTokenRequestToJSON,
    FxIdWebFeaturesAuthAuthenticateFromTokenResponseFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromTokenResponseToJSON,
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestToJSON,
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiResponseFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiResponseToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseToJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestToJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseToJSON,
    FxIdWebFeaturesAuthCreateTempTokenRequestFromJSON,
    FxIdWebFeaturesAuthCreateTempTokenRequestToJSON,
    FxIdWebFeaturesAuthCreateTempTokenResponseFromJSON,
    FxIdWebFeaturesAuthCreateTempTokenResponseToJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestFromJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestToJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseFromJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseToJSON,
    FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequestFromJSON,
    FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequestToJSON,
    FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseFromJSON,
    FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseToJSON,
    FxIdWebFeaturesAuthOauthUserInfoResponseFromJSON,
    FxIdWebFeaturesAuthOauthUserInfoResponseToJSON,
    FxIdWebFeaturesAuthTwitchConnectRequestFromJSON,
    FxIdWebFeaturesAuthTwitchConnectRequestToJSON,
    FxIdWebFeaturesAuthTwitchRefreshAccessTokenRequestFromJSON,
    FxIdWebFeaturesAuthTwitchRefreshAccessTokenRequestToJSON,
} from '../models';

export interface FxIdWebFeaturesAuthAuthenticateFromAwEndpointRequest {
    game: string;
    shopType: string;
    a: string;
    accountType: number;
    sign: string;
    uid: string;
    xsollaLoginToken?: string | null;
}

export interface FxIdWebFeaturesAuthAuthenticateFromEmailInviteEndpointRequest {
    fxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest: FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest;
}

export interface FxIdWebFeaturesAuthAuthenticateFromFacebookGamesEndpointRequest {
    game: string;
    fbLocale: string;
    signedRequest: string;
}

export interface FxIdWebFeaturesAuthAuthenticateFromFirebaseEndpointRequest {
    fxIdWebFeaturesAuthAuthenticateFromFirebaseRequest: FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest;
}

export interface FxIdWebFeaturesAuthAuthenticateFromGameEndpointRequest {
    fxIdWebFeaturesAuthAuthenticateFromGameRequest: FxIdWebFeaturesAuthAuthenticateFromGameRequest;
}

export interface FxIdWebFeaturesAuthAuthenticateFromImportEndpointRequest {
    fxIdWebFeaturesAuthAuthenticateFromImportRequest: FxIdWebFeaturesAuthAuthenticateFromImportRequest;
}

export interface FxIdWebFeaturesAuthAuthenticateFromSocialEndpointRequest {
    fxIdWebFeaturesAuthAuthenticateFromSocialRequest: FxIdWebFeaturesAuthAuthenticateFromSocialRequest;
}

export interface FxIdWebFeaturesAuthAuthenticateFromTokenEndpointRequest {
    fxIdWebFeaturesAuthAuthenticateFromTokenRequest: FxIdWebFeaturesAuthAuthenticateFromTokenRequest;
}

export interface FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiEndpointRequest {
    fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest;
}

export interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdEndpointRequest {
    fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest;
}

export interface FxIdWebFeaturesAuthAuthenticateWithExternalIdEndpointRequest {
    game: string;
    fxIdWebFeaturesAuthAuthenticateWithExternalIdRequest: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest;
}

export interface FxIdWebFeaturesAuthCreateTempTokenEndpointRequest {
    fxIdWebFeaturesAuthCreateTempTokenRequest: FxIdWebFeaturesAuthCreateTempTokenRequest;
}

export interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthEndpointRequest {
    fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest;
}

export interface FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthEndpointRequest {
    fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest: FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest;
}

export interface FxIdWebFeaturesAuthTwitchConnectEndpointRequest {
    fxIdWebFeaturesAuthTwitchConnectRequest: FxIdWebFeaturesAuthTwitchConnectRequest;
}

export interface FxIdWebFeaturesAuthTwitchRefreshAccessTokenEndpointRequest {
    fxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest: FxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest;
}

export interface GetFxIdWebFeaturesAuthOauthUserInfoEndpointRequest {
    game: string;
}

export interface PostFxIdWebFeaturesAuthOauthUserInfoEndpointRequest {
    game: string;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromAwEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateFromAwEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromAwEndpoint.');
        }

        if (requestParameters.shopType === null || requestParameters.shopType === undefined) {
            throw new runtime.RequiredError('shopType','Required parameter requestParameters.shopType was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromAwEndpoint.');
        }

        if (requestParameters.a === null || requestParameters.a === undefined) {
            throw new runtime.RequiredError('a','Required parameter requestParameters.a was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromAwEndpoint.');
        }

        if (requestParameters.accountType === null || requestParameters.accountType === undefined) {
            throw new runtime.RequiredError('accountType','Required parameter requestParameters.accountType was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromAwEndpoint.');
        }

        if (requestParameters.sign === null || requestParameters.sign === undefined) {
            throw new runtime.RequiredError('sign','Required parameter requestParameters.sign was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromAwEndpoint.');
        }

        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromAwEndpoint.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopType !== undefined) {
            queryParameters['shopType'] = requestParameters.shopType;
        }

        if (requestParameters.a !== undefined) {
            queryParameters['a'] = requestParameters.a;
        }

        if (requestParameters.accountType !== undefined) {
            queryParameters['accountType'] = requestParameters.accountType;
        }

        if (requestParameters.sign !== undefined) {
            queryParameters['sign'] = requestParameters.sign;
        }

        if (requestParameters.uid !== undefined) {
            queryParameters['uid'] = requestParameters.uid;
        }

        if (requestParameters.xsollaLoginToken !== undefined) {
            queryParameters['xsolla-login-token'] = requestParameters.xsollaLoginToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/authenticate_from_aw/{game}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromAwEndpoint(game: string, shopType: string, a: string, accountType: number, sign: string, uid: string, xsollaLoginToken?: string | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateFromAwEndpointRaw({ game: game, shopType: shopType, a: a, accountType: accountType, sign: sign, uid: uid, xsollaLoginToken: xsollaLoginToken }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromEmailInviteEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateFromEmailInviteEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest === null || requestParameters.fxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest','Required parameter requestParameters.fxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromEmailInviteEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/authenticate_from_email_invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequestToJSON(requestParameters.fxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromEmailInviteEndpoint(fxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest: FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateFromEmailInviteEndpointRaw({ fxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest: fxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromFacebookGamesEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateFromFacebookGamesEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromFacebookGamesEndpoint.');
        }

        if (requestParameters.fbLocale === null || requestParameters.fbLocale === undefined) {
            throw new runtime.RequiredError('fbLocale','Required parameter requestParameters.fbLocale was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromFacebookGamesEndpoint.');
        }

        if (requestParameters.signedRequest === null || requestParameters.signedRequest === undefined) {
            throw new runtime.RequiredError('signedRequest','Required parameter requestParameters.signedRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromFacebookGamesEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.fbLocale !== undefined) {
            formParams.append('FbLocale', requestParameters.fbLocale as any);
        }

        if (requestParameters.signedRequest !== undefined) {
            formParams.append('SignedRequest', requestParameters.signedRequest as any);
        }

        const response = await this.request({
            path: `/api/v1/auth/authenticate_from_facebook_games/{game}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromFacebookGamesEndpoint(game: string, fbLocale: string, signedRequest: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateFromFacebookGamesEndpointRaw({ game: game, fbLocale: fbLocale, signedRequest: signedRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromFirebaseEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateFromFirebaseEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthAuthenticateFromFirebaseRequest === null || requestParameters.fxIdWebFeaturesAuthAuthenticateFromFirebaseRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthAuthenticateFromFirebaseRequest','Required parameter requestParameters.fxIdWebFeaturesAuthAuthenticateFromFirebaseRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromFirebaseEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/authenticate_from_firebase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestToJSON(requestParameters.fxIdWebFeaturesAuthAuthenticateFromFirebaseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthAuthenticateFromFirebaseResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromFirebaseEndpoint(fxIdWebFeaturesAuthAuthenticateFromFirebaseRequest: FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateFromFirebaseEndpointRaw({ fxIdWebFeaturesAuthAuthenticateFromFirebaseRequest: fxIdWebFeaturesAuthAuthenticateFromFirebaseRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromGameEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateFromGameEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthAuthenticateFromGameResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthAuthenticateFromGameRequest === null || requestParameters.fxIdWebFeaturesAuthAuthenticateFromGameRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthAuthenticateFromGameRequest','Required parameter requestParameters.fxIdWebFeaturesAuthAuthenticateFromGameRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromGameEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/authenticate_from_game`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthAuthenticateFromGameRequestToJSON(requestParameters.fxIdWebFeaturesAuthAuthenticateFromGameRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthAuthenticateFromGameResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromGameEndpoint(fxIdWebFeaturesAuthAuthenticateFromGameRequest: FxIdWebFeaturesAuthAuthenticateFromGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthAuthenticateFromGameResponse> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateFromGameEndpointRaw({ fxIdWebFeaturesAuthAuthenticateFromGameRequest: fxIdWebFeaturesAuthAuthenticateFromGameRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromImportEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateFromImportEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthAuthenticateFromImportResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthAuthenticateFromImportRequest === null || requestParameters.fxIdWebFeaturesAuthAuthenticateFromImportRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthAuthenticateFromImportRequest','Required parameter requestParameters.fxIdWebFeaturesAuthAuthenticateFromImportRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromImportEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/authenticate_from_import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthAuthenticateFromImportRequestToJSON(requestParameters.fxIdWebFeaturesAuthAuthenticateFromImportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthAuthenticateFromImportResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromImportEndpoint(fxIdWebFeaturesAuthAuthenticateFromImportRequest: FxIdWebFeaturesAuthAuthenticateFromImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthAuthenticateFromImportResponse> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateFromImportEndpointRaw({ fxIdWebFeaturesAuthAuthenticateFromImportRequest: fxIdWebFeaturesAuthAuthenticateFromImportRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromSocialEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateFromSocialEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthAuthenticateFromSocialResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthAuthenticateFromSocialRequest === null || requestParameters.fxIdWebFeaturesAuthAuthenticateFromSocialRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthAuthenticateFromSocialRequest','Required parameter requestParameters.fxIdWebFeaturesAuthAuthenticateFromSocialRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromSocialEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/authenticate_from_social`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthAuthenticateFromSocialRequestToJSON(requestParameters.fxIdWebFeaturesAuthAuthenticateFromSocialRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthAuthenticateFromSocialResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromSocialEndpoint(fxIdWebFeaturesAuthAuthenticateFromSocialRequest: FxIdWebFeaturesAuthAuthenticateFromSocialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthAuthenticateFromSocialResponse> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateFromSocialEndpointRaw({ fxIdWebFeaturesAuthAuthenticateFromSocialRequest: fxIdWebFeaturesAuthAuthenticateFromSocialRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromTokenEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateFromTokenEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthAuthenticateFromTokenResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthAuthenticateFromTokenRequest === null || requestParameters.fxIdWebFeaturesAuthAuthenticateFromTokenRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthAuthenticateFromTokenRequest','Required parameter requestParameters.fxIdWebFeaturesAuthAuthenticateFromTokenRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromTokenEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/authenticate_from_temp_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthAuthenticateFromTokenRequestToJSON(requestParameters.fxIdWebFeaturesAuthAuthenticateFromTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthAuthenticateFromTokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromTokenEndpoint(fxIdWebFeaturesAuthAuthenticateFromTokenRequest: FxIdWebFeaturesAuthAuthenticateFromTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthAuthenticateFromTokenResponse> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateFromTokenEndpointRaw({ fxIdWebFeaturesAuthAuthenticateFromTokenRequest: fxIdWebFeaturesAuthAuthenticateFromTokenRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest === null || requestParameters.fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest','Required parameter requestParameters.fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/authenticate_from_vkontaktecom_openapi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestToJSON(requestParameters.fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiEndpoint(fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiResponse> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiEndpointRaw({ fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest: fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest === null || requestParameters.fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest','Required parameter requestParameters.fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/authenticate_to_fxid_adapter_for_external_id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestToJSON(requestParameters.fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdEndpoint(fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdEndpointRaw({ fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest: fxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateWithExternalIdEndpointRaw(requestParameters: FxIdWebFeaturesAuthAuthenticateWithExternalIdEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesAuthAuthenticateWithExternalIdEndpoint.');
        }

        if (requestParameters.fxIdWebFeaturesAuthAuthenticateWithExternalIdRequest === null || requestParameters.fxIdWebFeaturesAuthAuthenticateWithExternalIdRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthAuthenticateWithExternalIdRequest','Required parameter requestParameters.fxIdWebFeaturesAuthAuthenticateWithExternalIdRequest was null or undefined when calling fxIdWebFeaturesAuthAuthenticateWithExternalIdEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/authenticate_with_external_id/{game}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestToJSON(requestParameters.fxIdWebFeaturesAuthAuthenticateWithExternalIdRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthAuthenticateWithExternalIdEndpoint(game: string, fxIdWebFeaturesAuthAuthenticateWithExternalIdRequest: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse> {
        const response = await this.fxIdWebFeaturesAuthAuthenticateWithExternalIdEndpointRaw({ game: game, fxIdWebFeaturesAuthAuthenticateWithExternalIdRequest: fxIdWebFeaturesAuthAuthenticateWithExternalIdRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthCreateTempTokenEndpointRaw(requestParameters: FxIdWebFeaturesAuthCreateTempTokenEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthCreateTempTokenResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthCreateTempTokenRequest === null || requestParameters.fxIdWebFeaturesAuthCreateTempTokenRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthCreateTempTokenRequest','Required parameter requestParameters.fxIdWebFeaturesAuthCreateTempTokenRequest was null or undefined when calling fxIdWebFeaturesAuthCreateTempTokenEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/auth/create_temp_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthCreateTempTokenRequestToJSON(requestParameters.fxIdWebFeaturesAuthCreateTempTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthCreateTempTokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthCreateTempTokenEndpoint(fxIdWebFeaturesAuthCreateTempTokenRequest: FxIdWebFeaturesAuthCreateTempTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthCreateTempTokenResponse> {
        const response = await this.fxIdWebFeaturesAuthCreateTempTokenEndpointRaw({ fxIdWebFeaturesAuthCreateTempTokenRequest: fxIdWebFeaturesAuthCreateTempTokenRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthLogoutEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/auth/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesAuthLogoutEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesAuthLogoutEndpointRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthEndpointRaw(requestParameters: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest === null || requestParameters.fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest','Required parameter requestParameters.fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest was null or undefined when calling fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/oauth/mygames/info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestToJSON(requestParameters.fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthEndpoint(fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponse> {
        const response = await this.fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthEndpointRaw({ fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest: fxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthEndpointRaw(requestParameters: FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest === null || requestParameters.fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest','Required parameter requestParameters.fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest was null or undefined when calling fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/oauth/mygames/transfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequestToJSON(requestParameters.fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthEndpoint(fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest: FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse> {
        const response = await this.fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthEndpointRaw({ fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest: fxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthTwitchConnectEndpointRaw(requestParameters: FxIdWebFeaturesAuthTwitchConnectEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthTwitchConnectRequest === null || requestParameters.fxIdWebFeaturesAuthTwitchConnectRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthTwitchConnectRequest','Required parameter requestParameters.fxIdWebFeaturesAuthTwitchConnectRequest was null or undefined when calling fxIdWebFeaturesAuthTwitchConnectEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/auth/twitch/connect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthTwitchConnectRequestToJSON(requestParameters.fxIdWebFeaturesAuthTwitchConnectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthTwitchConnectEndpoint(fxIdWebFeaturesAuthTwitchConnectRequest: FxIdWebFeaturesAuthTwitchConnectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse> {
        const response = await this.fxIdWebFeaturesAuthTwitchConnectEndpointRaw({ fxIdWebFeaturesAuthTwitchConnectRequest: fxIdWebFeaturesAuthTwitchConnectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesAuthTwitchRefreshAccessTokenEndpointRaw(requestParameters: FxIdWebFeaturesAuthTwitchRefreshAccessTokenEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponse>> {
        if (requestParameters.fxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest === null || requestParameters.fxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest','Required parameter requestParameters.fxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest was null or undefined when calling fxIdWebFeaturesAuthTwitchRefreshAccessTokenEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/auth/twitch/refresh_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesAuthTwitchRefreshAccessTokenRequestToJSON(requestParameters.fxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesAuthTwitchRefreshAccessTokenEndpoint(fxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest: FxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponse> {
        const response = await this.fxIdWebFeaturesAuthTwitchRefreshAccessTokenEndpointRaw({ fxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest: fxIdWebFeaturesAuthTwitchRefreshAccessTokenRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFxIdWebFeaturesAuthOauthUserInfoEndpointRaw(requestParameters: GetFxIdWebFeaturesAuthOauthUserInfoEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthOauthUserInfoResponse>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling getFxIdWebFeaturesAuthOauthUserInfoEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/oauth/userinfo/{game}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthOauthUserInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async getFxIdWebFeaturesAuthOauthUserInfoEndpoint(game: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthOauthUserInfoResponse> {
        const response = await this.getFxIdWebFeaturesAuthOauthUserInfoEndpointRaw({ game: game }, initOverrides);
        return await response.value();
    }

    /**
     */
    async postFxIdWebFeaturesAuthOauthUserInfoEndpointRaw(requestParameters: PostFxIdWebFeaturesAuthOauthUserInfoEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesAuthOauthUserInfoResponse>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling postFxIdWebFeaturesAuthOauthUserInfoEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/oauth/userinfo/{game}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesAuthOauthUserInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async postFxIdWebFeaturesAuthOauthUserInfoEndpoint(game: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesAuthOauthUserInfoResponse> {
        const response = await this.postFxIdWebFeaturesAuthOauthUserInfoEndpointRaw({ game: game }, initOverrides);
        return await response.value();
    }

}
