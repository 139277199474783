/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData,
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData;

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData);
    }

    return {};
}

