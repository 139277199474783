/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData,
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData;

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData);
    }

    return {};
}

