/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData,
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData;

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData);
    }

    return {};
}

