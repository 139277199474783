/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData
 */
export interface FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData
     */
    Token: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Token" in value;

    return isInstance;
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Token': json['Token'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataToJSON(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Token': value.Token,
    };
}

