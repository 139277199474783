/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthData,
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthData';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthData;

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthDataFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersFacebookAuthDataFacebookAuthData);
    }

    return {};
}

