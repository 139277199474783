/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken } from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType } from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest {
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    AuthenticationType: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    Locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    SessionId?: string | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    SteamLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    SteamOpenIdLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    EgsLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    GoogleLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    FacebookLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    AuthenticateWithExternalIdGameAccessToken?: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AuthenticationType" in value;
    isInstance = isInstance && "Game" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AuthenticationType': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeFromJSON(json['AuthenticationType']),
        'Game': json['Game'],
        'Locale': !exists(json, 'Locale') ? undefined : json['Locale'],
        'SessionId': !exists(json, 'SessionId') ? undefined : json['SessionId'],
        'SteamLogin': !exists(json, 'SteamLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLoginFromJSON(json['SteamLogin']),
        'SteamOpenIdLogin': !exists(json, 'SteamOpenIdLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLoginFromJSON(json['SteamOpenIdLogin']),
        'EgsLogin': !exists(json, 'EgsLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLoginFromJSON(json['EgsLogin']),
        'GoogleLogin': !exists(json, 'GoogleLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLoginFromJSON(json['GoogleLogin']),
        'FacebookLogin': !exists(json, 'FacebookLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLoginFromJSON(json['FacebookLogin']),
        'AuthenticateWithExternalIdGameAccessToken': !exists(json, 'AuthenticateWithExternalIdGameAccessToken') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenFromJSON(json['AuthenticateWithExternalIdGameAccessToken']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestToJSON(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AuthenticationType': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeToJSON(value.AuthenticationType),
        'Game': value.Game,
        'Locale': value.Locale,
        'SessionId': value.SessionId,
        'SteamLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamLoginToJSON(value.SteamLogin),
        'SteamOpenIdLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestSteamOpenIdLoginToJSON(value.SteamOpenIdLogin),
        'EgsLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestEgsLoginToJSON(value.EgsLogin),
        'GoogleLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestGoogleLoginToJSON(value.GoogleLogin),
        'FacebookLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestFacebookLoginToJSON(value.FacebookLogin),
        'AuthenticateWithExternalIdGameAccessToken': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenToJSON(value.AuthenticateWithExternalIdGameAccessToken),
    };
}

